import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const isDevMode = () => process.env.NODE_ENV === 'development'

const themeConfig = {
  app: {
    version: '1.4.2b',
    name: isDevMode() ? 'Skilled Woof Cats' : 'Skilled Wound Care',
    title: 'Treasure X',
    logo: require('@/assets/images/logos/swc-icon.png'),
    isDark: false, // app designed assuming false
    isRtl: false,
    contentLayoutNav: 'vertical',
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    skinVariant: 'semi-dark', // app designed assuming semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'static', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#00a3e0',
      accent: '#0a0911',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#2BB770',
      warning: '#FFB400',
      error: '#FF4C51',
    },

    /* Not used, uses inversion for dark theme/mode
    dark: {
      primary: '#005270',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#2BB770',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    */
  },
}

export default themeConfig
